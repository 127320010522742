exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-quote-tsx": () => import("./../../../src/pages/quote.tsx" /* webpackChunkName: "component---src-pages-quote-tsx" */),
  "component---src-pages-request-quote-tsx": () => import("./../../../src/pages/request-quote.tsx" /* webpackChunkName: "component---src-pages-request-quote-tsx" */),
  "component---src-pages-test-carousel-tsx": () => import("./../../../src/pages/test-carousel.tsx" /* webpackChunkName: "component---src-pages-test-carousel-tsx" */),
  "component---src-templates-home-index-dynamic-tsx": () => import("./../../../src/templates/home/index-dynamic.tsx" /* webpackChunkName: "component---src-templates-home-index-dynamic-tsx" */),
  "component---src-templates-services-index-dynamic-tsx": () => import("./../../../src/templates/services/index-dynamic.tsx" /* webpackChunkName: "component---src-templates-services-index-dynamic-tsx" */),
  "slice---src-shared-core-footer-footer-tsx": () => import("./../../../src/shared/core/footer/Footer.tsx" /* webpackChunkName: "slice---src-shared-core-footer-footer-tsx" */),
  "slice---src-shared-core-header-header-tsx": () => import("./../../../src/shared/core/header/Header.tsx" /* webpackChunkName: "slice---src-shared-core-header-header-tsx" */)
}

